import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import RegionalPosting from "./RegionalPosting";
import CreateInvoiceNicheJobBoardPayment from "../components/CreateInvoiceNicheJobBoardPayment";
import CountryList from "../data/CountryList.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExternalLinkAlt,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import AcademicConnections from "../components/AcademicConnectionsCount";

const JobList1 = ({ endOfJobs, data, jobBoardData, nextPage }) => {
  const navigate = useNavigate();

  const userInfo = useSelector((state) => state.auth.userInfo);
  const jobPostCreditCount = useSelector((state) => state.posts.jobCredits);

  const [showDetails, setShowDetails] = useState({}); // Track toggle state for each job

  const toggleDetails = (id) => {
    setShowDetails((prevState) => ({
      ...prevState,
      [id]: !prevState[id], // Toggle the state for the specific job
    }));
  };

  const content = data.map((job, index) => {
    const {
      expiration_date,
      id,
      academic_connections_count,
      academic_connections, // Assuming this contains the firstname and email domain
    } = job;

    return (
      <div
        className={`bg-white border mt-2 relative border-gray-200 p-4 mb-8 rounded-xl shadow-lg block font-bold`}
        key={index}
      >
        <div className="flex justify-between">
          <div className="flex items-center pb-2">
            <div className="flex-1">
              <span className="">
                <a
                  className="text-gray-500 text-xl mr-4 font-bold leading-tight hover:underline cursor-pointer"
                  href={`https://www.academicjobs.com/jobs/myjob/${job.id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {job.title}
                </a>
              </span>
            </div>
          </div>
        </div>

        <div className="h-16">
          {" "}
          {/* Add a height or ensure the parent has space for the content */}
          <div className="flex w-[95%] md:absolute md:left-4 md:bottom-4">
            <AcademicConnections
              id={id}
              academic_connections_count={academic_connections_count}
              academic_connections={academic_connections}
            />
          </div>
          <div className="flex md:absolute md:right-4 md:bottom-4">
            <div className="w-full sm:w-auto">
              <a
                className="btn text-amber-500 hover:underline text-xs cursor-pointer w-full sm:w-auto"
                href={`https://www.academicjobs.com/jobs/myjob/${job.id}?mode=share`}
                target="_blank"
                rel="noreferrer"
              >
                <div className="flex items-center gap-2">
                  <img className="w-7" src="/icons/network-icon.svg" alt="" />
                  <span>Academic Connect</span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      {" "}
      {/* Move grid container here */}
      {content}
    </div>
  );
};

export default JobList1;
