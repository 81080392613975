'use client';
import { useState, useEffect } from 'react';
import { productData } from '../data/ProductData';
import { useNavigate } from 'react-router-dom'; // Ensure this import is here

const PricingTable = ({ currency = 'AUD', hideRegionSelector = false }) => {
  const regions = {
    Australia: 'AUD',
    'New Zealand': 'NZD',
    USA: 'USD',
    Other: 'USD',
  };

  const navigate = useNavigate();
  const [selectedCurrency, setSelectedCurrency] = useState(currency);
  const [products, setProducts] = useState(productData[currency]);

  useEffect(() => {
    setProducts(productData[selectedCurrency]);
  }, [selectedCurrency]);

  const handleSelectProduct = (product, region) => {
    navigate('/save-pay-post', { state: { selectedProduct: product, selectedRegion: region } });
  };

  const formatNumberWithCommas = (number) => {
    return number.toLocaleString();
  };

  const handleChange = (event) => {
    setSelectedCurrency(event.target.value);
  };

  const productDetails = [
    [
      '30 day single job listing',
      'Free employer account',
      'Edit job postings anytime',
      'Academic Job Board',
      <a className="ml-3">
        Upgrade to <span className="text-black underline">Featured Listing</span>
      </a>,
    ],
    [
      '30 day job listings',
      'Free employer account',
      'Post anytime (no expiry)',
      'Edit job postings anytime',
      '23% cheaper than Seek',
    ],
    [
      'Unlimited Job Ads',
      `All ${products[1].name} features`,
      '1 featured listing per month',
      'Talent Acquisition support',
      selectedCurrency === 'AUD'
        ? '⭐ AHEIA Members Only'
        : 'Save 20k+ per year',
    ],
  ];

  return (
    <div className="max-w-6xl mx-auto pt-4 px-8">
      {!hideRegionSelector && (
        <>
          <label htmlFor="currency" className="label-text text-xs">
            {selectedCurrency ? 'Region' : 'Please select a region'}
          </label>
          <select
            id="currency"
            value={selectedCurrency}
            onChange={handleChange}
            name="currency"
            className="select select-bordered w-full bg-white focus:outline-none focus:border-orange-500 mb-4"
            required
          >
            {Object.keys(regions).map((key) => (
              <option key={key} value={regions[key]}>
                {key}
              </option>
            ))}
          </select>
        </>
      )}

      <h2 className="underline-full gray-blue">
        {selectedCurrency === 'AUD' ? 'Special: AHEIA Members Only ' : 'Pricing '} {selectedCurrency}
      </h2>

      <div
        id="pricing-table-cards"
        className="flex flex-col justify-between items-center lg:flex-row lg:items-start mb-16"
      >
        {products.map((product, index) => (
          <div
            key={product.id}
            className={`w-full flex-1 mt-8 mb-8 p-8 bg-white shadow-xl rounded-3xl sm:w-96 lg:w-full ${index === 0 ? 'lg:rounded-r-none' : ''} ${index === 2 ? 'lg:rounded-l-none' : ''}`}
          >
            <div className="mb-7 pb-7 flex items-center border-b border-gray-600">
              <img
                width="150"
                height="150"
                alt=""
                src={`/pricing-table/abstract-${index + 1}.jpg`}
                className="rounded-3xl w-20 h-20"
              />
              <div className="ml-5">
                <span className="block text-2xl font-semibold text-black">
                  {product.name}
                </span>
                <span>
                  <span className="font-medium text-gray-500 text-xl align-top">
                    {product.currencySymbol}&thinsp;
                  </span>
                  <span className="text-3xl font-bold text-black">
                    {formatNumberWithCommas(product.price)}
                  </span>
                  <span className="text-[12px]">{selectedCurrency}</span>
                  <p className="m-0 mt-[-4px] text-[12px]">{index === 2 ? '/monthly' : '/per job pack'}</p>
                </span>
              </div>
            </div>
            <ul className="mb-7 font-medium text-gray-500">
              {productDetails[index].map((detail, detailIndex) => (
                <li className="flex text-lg mb-2" key={detailIndex}>
                  <img
                    width="20"
                    height="20"
                    alt=""
                    src="/icons/check-grey.svg"
                  />
                  <span className="ml-3">{detail}</span>
                </li>
              ))}
            </ul>
            <button
              onClick={() => handleSelectProduct(product, selectedCurrency)}
              className="flex mx-auto justify-center items-center bg-aj rounded-xl py-5 px-4 text-center text-white font-bold text-xl"
            >
              {index === 2 ? `Subscribe to ${product.name}` : `Buy ${product.name}`}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PricingTable;
