import React, { useState, useEffect, useRef, useMemo } from "react";
import { useForm } from "react-hook-form";
import Autocomplete, { usePlacesWidget } from "react-google-autocomplete";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector, useDispatch } from "react-redux";
import {
  usePostAJobMutation,
  useUpdateJobMutation,
  useDeductJobCreditsMutation
} from "../store/apiSlice";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useLocation } from "react-router-dom";

import InputBlock2 from "./InputBlock2";
import SelectBlock2 from "./SelectBlock2";
import { currencies } from "../utils/data";
import { setEmployer } from "../store/postsSlice";
import JobKeywordSearchBlock from "../views/JobKeywordSearchBlock";
import CreateInvoiceEmployer from "../components/CreateInvoiceEmployer";
const validationSchema = yup
  .object({
    // Your validation schema
  })
  .required();

const JobAddEdit_Employer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const job = location.state?.job || null;
  const userInfo = useSelector((state) => state.auth.userInfo);
  const jobPostCreditCount = useSelector((state) => state.posts.jobCredits);

  const [showReferralModal, setShowReferralModal] = useState(false);
  const [referralLink, setReferralLink] = useState('');

  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [paymentConfirmed, setPaymentConfirmed] = useState(false); 

  const handlePaymentSuccess = () => {
    setPaymentConfirmed(true);
    setShowPaymentModal(false);
    submitJob(); // Submit the form programmatically after payment
  };

  const handlePaymentCancel = () => {
    setShowPaymentModal(false);
  };
  const isAddMode = !job || Object.keys(job).length === 0;

  const [secondCategory, setSecondCategory] = useState(job?.thirdcategory ?? null);

  let defaultValues;
  let buttonText;
  const {
    control,
    register,
    reset,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { errors },
    setError,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: useMemo(() => {
      return defaultValues;
    }, [defaultValues]),
  });

  let master_category_job_type = watch("master_category_job_type");

  useEffect(() => {
    if (!job) {
      buttonText = "Post Job";
      reset({
        salary_period: "yearly",
        currency: "USD",
        activation_date: new Date().toISOString().substring(0, 10),
        expiration_date: new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000).toISOString().substring(0, 10),
        remote: "Onsite",
        position_type: "",
      });
      if (userInfo?.jobCredits == -1) {
        setValue("featured", true);
      }
      master_category_job_type = "";
      setValue("master_category_job_type", "");
    } else {
      buttonText = "Update Job";
      reset(job);
      master_category_job_type = job.master_category_job_type;
      setValue("activation_date", new Date(job.activation_date).toISOString().split("T")[0]);
      setValue("expiration_date", new Date(job.expiration_date).toISOString().split("T")[0]);
      dispatch(
        setEmployer({
          company_name: job?.company_name,
          employer_id: job.employer_id,
          logo: job.logo,
          // employerPageURL: job.employer?.employerPageURL,
          clientType: job.clientType,
        })
      );
    }
  }, [job]);

  useEffect(() => {
    register("description", { required: false });
    register("company_name1", { required: false });
  }, [register]);

  useEffect(() => {
    if (!job) {
      setValue("company_name1", userInfo?.company_name);
      setValue("employer_id", userInfo?.employer_id);
    }
  }, [userInfo]);

  const { ref } = usePlacesWidget({
    apiKey: "AIzaSyCKEfoOIPz8l_6A8BByD3b3-ncwza8TNiA", //process.env.REACT_APP_GOOGLE,
    options: {
      componentRestrictions: { country: "Australia" },
    },
    onPlaceSelected: (place) => {
      const addressComponents = place.address_components || [];
      let city = "";
      let state = "";
      let country1 = "";
      let postalCode = "";
      addressComponents.forEach((component) => {
        const types = component.types || [];
        if (types.includes("locality")) {
          city = component.long_name;
        } else if (types.includes("administrative_area_level_1")) {
          state = component.long_name;
        } else if (types.includes("country")) {
          country1 = component.long_name;
        } else if (types.includes("postal_code")) {
          postalCode = component.long_name;
        }
      });

      setValue("location", place.formatted_address);
      setValue("city", city);
      setValue("state", state);
      setValue("country", country1);
      setValue("region", country1);
      setValue("postalCode", postalCode);
    },
  });

  const jobStatusDefault = job ? "Update Job" : (userInfo.employer_id !== undefined && userInfo.jobCredits === 0 ? "Save, Pay & Post" : "Post");
  const [jobStatus, setJobStatus] = useState(jobStatusDefault);
  const editorContent = watch("description");

  const inputRef = useRef();
  const submitRef = useRef();
  const countryRef = useRef("");
  const [updateJob, { isLoading: isLoadingUpdateJob, isSuccess: isSuccessUpdateJob, isError: isErrorUpdateJob, error: errorUpdateJob }] = useUpdateJobMutation();
  const [postAJob, { data: dataPostAJob, isLoading: isLoadingPostAJob, isSuccess: isSuccessPostAJob, isError: isErrorPostAJob, error: errorPostAJob }] = usePostAJobMutation();
  const [deductJobCredit, { data: dataJobCredits, isLoading: isLoadingJobCredits }] = useDeductJobCreditsMutation();

  const job_type = ["Full time", "Part time", "Contractor", "Sessional"];

  const onEditorStateChange = (editorState) => {
    setValue("description", editorState);
  };

  let content;

  // Form Submission Logic
  const submitJob = handleSubmit(async (data) => {
    if (isAddMode) {
      await createJob(data);
    } else {
      await updateJob1({ id: job?.id, ...data });
    }
  });

  const onSubmit = async (data) => {
    if (jobPostCreditCount === 0 && !paymentConfirmed) {
      // Show payment modal if no job credits and payment is not confirmed yet
      setShowPaymentModal(true);
      return;
    }
    await submitJob();
  };
  async function createJob(data) {
    try {
      setJobStatus('Job Posting ......!');

      // Call the postAJob function and destructure the response status and data
      const response = await postAJob({
        ...data,
        postedBy: userInfo.id,
        headlineOnly: false,
      });

      // Check if the response status is 200 and if the id exists
      if (response?.data?.id) {
        const { data: { id } } = response;
        setJobStatus('Job POSTED!');

        if (jobPostCreditCount > 0) {
          await deductJobCredit({ userId: userInfo.id });
        }

        // Show referral modal if the job is not headlineOnly
        setReferralLink(`https://academicjobs.com/jobs/myjob/${id}?mode=share`);
        setShowReferralModal(true);
      } else {
        setJobStatus('Failed to post the job. Please try again.');
      }
    } catch (error) {
      console.error('Error posting job:', error);
      setJobStatus('Error posting job!');
    }
  }

  async function updateJob1(data) {
    setJobStatus("Updating Job...");
    const response = await updateJob(data);
    if (response) {
      setJobStatus("All Done!");
      setTimeout(() => {
        setJobStatus("Update Job");
      }, 10000);
    }
  }

  content = (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="w-full grid grid-cols-1 gap-8 sm:grid-cols-2">
        <div className="w-full flex flex-col gap-2">
          <label className="label-text  text-lg mt-4 font-bold">Job Title</label>
          <InputBlock2 placeholder='Job title' type="text" field="title" label="" register={register} errors={errors} forceClass="" />
          <div className="flex gap-2 items-center justify-between">
            {userInfo.region == 'Australia' &&
              <div className="form-control">
                <label className="cursor-pointer label">
                  <input
                    type="checkbox"
                    {...register("indigenousJobYN")}
                    className="checkbox checkbox-warning checkbox-sm mr-3"
                  />

                  <span className="label-text">
                    Indigenous Job
                  </span>
                  <figure>
                    <img
                      src={'/australian-aboriginal-flag.png'}
                      alt="Indigenous Australian Aboriginal Flag"
                      width={25}
                      height={25}
                      className="ml-2 max-w-7"
                    />
                  </figure>
                  <figure>
                    <img
                      src={'/australia-torres-streight-islanders.svg'}
                      alt="Indigenous Australian Aboriginal Flag"
                      width={25}
                      height={25}
                      className="ml-2 max-w-7"
                    />
                  </figure>
                  <figure>
                    <img
                      src={'/Flag-of-Maori.png'}
                      alt="Maori Flag"
                      width={25}
                      height={25}
                      className="ml-2 max-w-7"
                    />
                  </figure>
                </label>
              </div>}
            {userInfo.employer_id === undefined ? (
              <>
                <div className=" w-[100%] mx-auto flex justify-start items-start  gap-1 ">
                  <input type="checkbox" {...register("internalonly")} />
                  <label className="label-text text-sm">Hide Job</label>
                </div>
                <div className=" w-[100%] mx-auto flex justify-start items-start  gap-1 ">
                  <input type="checkbox" {...register("headlineOnly")} />
                  <label className="label-text text-sm">Headline Only</label>
                </div>
              </>
            ) : null}
          </div>
          <label className="label-text mb-1 font-bold text-lg">Job Specialty</label>
          <JobKeywordSearchBlock
            customKey="Enter a keyword"
            label="Enter Keyword and select it from the drop-down list"
            value1={secondCategory}
            forceClass="mb-8"
            required={true}
            onSelect={(realCtg, category2, category01, category01A, category02, category02A) => {
              setValue(realCtg, category2);
              if (category01) setValue(category01, category01A);
              if (category02) setValue(category02, category02A);
              if (realCtg == "thirdcategory") setSecondCategory(category02A);
            }}
            className="w-full"
          />
          <span className="label-text text-lg font-bold mt-4">Employment Type</span>
          <SelectBlock2 list={job_type} field="job_type" register={register} errors={errors} />
          <span className="label-text  text-lg font-bold mt-4">Job Location</span>
          <div>
            <Autocomplete
              className="border p-1 rounded-lg"
              style={{ width: "100%" }}
              apiKey="AIzaSyCKEfoOIPz8l_6A8BByD3b3-ncwza8TNiA"
              {...register("location1")}
              onPlaceSelected={(place) => {
                const addressComponents = place.address_components || [];
                let city = "";
                let state = "";
                let country1 = "";
                let postalCode = "";
                addressComponents.forEach((component) => {
                  const types = component.types || [];
                  if (types.includes("locality")) {
                    city = component.long_name;
                  } else if (types.includes("administrative_area_level_1")) {
                    state = component.long_name;
                  } else if (types.includes("country")) {
                    country1 = component.long_name;
                  } else if (types.includes("postal_code")) {
                    postalCode = component.long_name;
                  }
                });

                setValue("location", place.formatted_address);
                setValue("city", city);
                setValue("state", state);
                setValue("country", country1);
                setValue("region", country1);
                setValue("postalCode", postalCode);
              }}
              options={{
                types: ["geocode", "establishment"],
              }}
              onChange={(e) => setValue("location", e.target.value)}
            />
            <div>{job?.location}</div>
            <div className="flex items-center justify-start mt-4">
              <div className="form-control items-start mb-2 mr-4">
                <label className="flex items-start justify-start label cursor-pointer">
                  <input {...register("remote")} type="radio" className="radio radio-warning radio-xs mr-1" value="Onsite" />
                  <span className="label-text font-bold pb-2 text-sm">Onsite</span>
                </label>
              </div>
              <div className="form-control items-start mb-2 mr-4">
                <label className="flex items-start justify-start label cursor-pointer">
                  <input {...register("remote")} type="radio" className="radio radio-warning radio-xs mr-1" value="Remote" />
                  <span className="label-text font-bold pb-2 text-sm">Remote</span>
                </label>
              </div>
              <div className="form-control items-start mb-2">
                <label className="flex items-start justify-start label cursor-pointer">
                  <input {...register("remote")} type="radio" className="radio radio-warning radio-xs mr-1" value="Hybrid" />
                  <span className="label-text font-bold pb-2 text-sm">Hybrid</span>
                </label>
              </div>
            </div>
          </div>
          <span className="label-text  text-lg font-bold">Salary Range</span>
          <div className="join flex gap-2">
            <div className="max-w-[80px]">
              <div>
                <SelectBlock2
                  list={currencies}
                  field="currency"
                  label="Currency"
                  register={register}
                  errors={errors}
                  forceClass="join-item rounded-r-none min-h-[34px]"
                />
              </div>
            </div>
            <div className="flex-grow">
              <InputBlock2 type="text" field="salary_from" label="$From" register={register} errors={errors} forceClass="rounded-none min-h-[34px]" />
            </div>
            <div className="flex-grow">
              <InputBlock2 type="text" field="salary_to" label="$To" register={register} errors={errors} forceClass="rounded-none min-h-[34px]" />
            </div>
            <div className="max-w-[100px]">
              <div>
                <SelectBlock2
                  list={["yearly", "monthly", "weekly", "daily", "hourly"]}
                  field="frequency"
                  label="Frequency"
                  register={register}
                  errors={errors}
                  forceClass="join-item rounded-l-none min-h-[34px]"
                />
              </div>
            </div>
          </div>
          <label className="label-text font-bold text-lg mt-4">How To Apply</label>
          <InputBlock2 placeholder="Job Post URL"type="text" field="how_to_apply" label="" register={register} errors={errors} forceClass="" />
          <div className="grid grid-cols-2 gap-4 mb-4 mt-8">

            <InputBlock2
              type="date"
              field="activation_date"
              label="Post Date"
              register={register}
              errors={errors}
              forceClass="mb-6 font-bold text-xl"
            />
            <InputBlock2
              type="date"
              field="expiration_date"
              label="Expiration Date"
              register={register}
              errors={errors}
              forceClass="mb-6 font-bold"
            />
          </div>

        </div>
        <div className="w-full flex flex-col">
          <div className="mb-6 mt-4 pb-6">
            <label className="label-text pb-2 text-lg mt-4 font-bold">Job Description</label>
            <ReactQuill className=" job_post_editor.quill" value={editorContent} onChange={onEditorStateChange} style={{ minHeight: '650px' }} // Set your desired height here
            />
          </div>
          <div className="flex justify-end my-9">
            <button type="submit" className="btn btn-success" ref={submitRef} >
              {jobStatus}
            </button>
          </div>

        </div>

      </form>
      {showPaymentModal && 
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-8 w-full max-w-2xl relative">
            <button
              className="absolute top-0 right-0 p-2 text-gray-500 hover:text-gray-800"
              onClick={handlePaymentCancel}
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
            <h2 className="text-2xl font-bold mb-4 text-center">Payment Required</h2>
            <CreateInvoiceEmployer employer={userInfo} onPaymentComplete={handlePaymentSuccess} />
          </div>
        </div>
      }
      {/* Modal HTML */}
      {showReferralModal && (

        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-8 w-full max-w-md relative">
            <button
              className="absolute top-0 right-0 p-2 text-gray-500 hover:text-gray-800"
              onClick={() => setShowReferralModal(false)}
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
            <h2 className="text-2xl font-bold mb-4 text-center">Your job has been posted!</h2>
            <p className="mb-4 text-center"> Send out referrals now using <span className="font-bold text-amber-500">Academic</span><span className="font-bold text-black">Connect</span></p>
            <div className="flex flex-col items-center pb-4">
              <p className="text-sm italic leading-tight">
                Referred applicants are{" "}
                <a
                  target="_blank"
                  className={`font-bold text-[#735a7a]`}
                  rel="noreferrer"
                >
                  more qualified
                </a>
              </p>
              <p className="text-sm italic leading-tight">
                Referred candidates are{" "}
                <a
                  target="_blank"
                  className={`font-bold text-[#a66c98]`}
                  rel="noreferrer"
                >
                  hired faster
                </a>
              </p>
              <p className="text-sm italic leading-tight mb-0">
                Referred employees{" "}
                <a
                  target="_blank"
                  className={`font-bold text-[#b56576]`}
                  rel="noreferrer"
                >
                  stick around longer
                </a>
              </p>
            </div>
            <a
              className={
                ' flex w-[20rem] mt-4 justify-center mx-auto border-amber-500 bg-gray-100 text-gray-600 hover:bg-gray-200 hover:text-gray-700 rounded-full btn'
              }
              href={referralLink}
            >
              <img className="h-[1.2rem] mr-2" src="/icons/network-icon.svg" />
              Academic Connect
            </a>
          </div>
        </div>
        )}
    </>
  );
  return <div className="overflow-y w-full mt-8">{content}</div>;
};

export default JobAddEdit_Employer;

